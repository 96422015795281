import { Elm } from '../elm/Login/Main.elm'
import { getInitialValue } from './common.js'
import initSegment from './usertracking'

const initialValue = getInitialValue()

// detect support for the behavior property in ScrollOptions
const supportsNativeSmoothScroll =
  'scrollBehavior' in document.documentElement.style

// detect which browser we're using. This can be extended
const isChrome = navigator.userAgent.indexOf('Chrome') >= 0
const isSafari =
  navigator.userAgent.indexOf('Safari') >= 0 &&
  navigator.userAgent.indexOf('Chrome') < 0
const isFireFox = navigator.userAgent.indexOf('Firefox') >= 0

// hand tested these browsers, so they're known to work with the sticky header
const supportsStickyPosition = isChrome || isSafari || isFireFox

let app = Elm.Login.Main.init({ flags: initialValue })
initSegment(initialValue.segmentWriteKey)

if (app) {
  if (app.ports.scrollToTop) {
    app.ports.scrollToTop.subscribe(function (offset) {
      window.scrollTo(0, offset)
    })
  }

  if (app.ports.scrollToId) {
    app.ports.scrollToId.subscribe(function ({ id, offset = 0 }) {
      const queryForElem = () => document.getElementById(id)
      makeScrollAttempt(queryForElem, offset)
    })
  }

  if (app.ports.scrollToFirstStepError) {
    app.ports.scrollToFirstStepError.subscribe(function (offset) {
      let queryForElem = () => {
        const errorElms = document.getElementsByClassName('error')
        const errorArray = Array.from(errorElms)

        const stepErrors = errorArray.filter(e => {
          const classes = Array.from(e.classList)
          return classes.indexOf('step') > -1
        })

        if (stepErrors.length) {
          const errorElem = stepErrors[0]
          return errorElem
        } else {
          return null
        }
      }
      makeScrollAttempt(queryForElem, offset)
    })
  }

  if (app.ports.initHelloSign) {
    app.ports.initHelloSign.subscribe(function (args) {
      const signUrl = args[0]
      const clientId = args[1]
      initHelloSign(signUrl, clientId, app.ports.completeHelloSign)
    })
  }

  if (app.ports.copyTextToClipboard) {
    app.ports.copyTextToClipboard.subscribe(function (text) {
      copyTextToClipboard(text, app.ports.copyTextToClipboardComplete.send)
    })
  }

  if (app.ports.correlatedCopyTextToClipboard) {
    app.ports.correlatedCopyTextToClipboard.subscribe(function ({
      correlationId,
      text,
    }) {
      const onComplete = function (result) {
        return app.ports.correlatedCopyTextToClipboardComplete.send({
          correlationId: correlationId,
          result: result,
        })
      }

      copyTextToClipboard(text, onComplete)
    })
  }

  // Segment Analytics
  if (app.ports.identify) {
    app.ports.identify.subscribe(function ({ userId, ...rest }) {
      window.analytics.identify(userId, rest)
    })
  }
  if (app.ports.pageView) {
    app.ports.pageView.subscribe(function ({ name, ...properties }) {
      window.analytics.page(name, properties)
    })
  }
  if (app.ports.track) {
    app.ports.track.subscribe(function ({ eventType, eventPayload }) {
      window.analytics.track(eventType, eventPayload)
    })
  }
}

if (initialValue.isStaging) {
  document.documentElement.className += ' staging'
}

function initHelloSign(signUrl, clientId, port) {
  const skipVerification = document.location.hostname === 'localhost'
  if (window.HelloSign) {
    HelloSign.init(clientId)
    HelloSign.open({
      url: signUrl,
      uxVersion: 2,
      allowCancel: false,
      skipDomainVerification: skipVerification,
      messageListener: function (eventData) {
        if (eventData.event === HelloSign.EVENT_SIGNED) {
          port.send(true)
          HelloSign.close()
        }
      },
    })
  }
}

/// From https://stackoverflow.com/a/30810322/1191
function copyTextToClipboard(text, onComplete) {
  let textArea = document.createElement('textarea')

  // Place in top-left corner of screen regardless of scroll position.
  textArea.style.position = 'fixed'
  textArea.style.top = 0
  textArea.style.left = 0

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = '2em'
  textArea.style.height = '2em'

  // We don't need padding, reducing the size if it does flash render.
  textArea.style.padding = 0

  // Clean up any borders.
  textArea.style.border = 'none'
  textArea.style.outline = 'none'
  textArea.style.boxShadow = 'none'

  // Avoid flash of white box if rendered for any reason.
  textArea.style.background = 'transparent'

  textArea.value = text

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    const result = document.execCommand('copy')
    onComplete(result)
  } catch (e) {
    console.log(e)
  }

  document.body.removeChild(textArea)
}

/**
 * Alternate scrolling for browsers that don't support smooth scrolling.
 * Mostly a copy/paste of this Gist https://gist.github.com/eyecatchup/d210786daa23fd57db59634dd231f341
 * @param {Integer} to the pixel position to scroll to
 * @param {Integer} duration milliseconds
 */
const alternateScrollTo = (to, duration) => {
  const element = document.scrollingElement || document.documentElement,
    start = element.scrollTop,
    change = to - start,
    startDate = new Date().getTime()

  /*
      t = current time
      b = start value
      c = change in value
      d = duration
     */
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2
    if (t < 1) return (c / 2) * t * t + b
    t--
    return (-c / 2) * (t * (t - 2) - 1) + b
  }

  const animateScroll = () => {
    const currentDate = new Date().getTime()
    const currentTime = currentDate - startDate
    element.scrollTop = parseInt(
      easeInOutQuad(currentTime, start, change, duration)
    )
    if (currentTime < duration) {
      requestAnimationFrame(animateScroll)
    } else {
      element.scrollTop = to
    }
  }
  animateScroll()
}

/**
 * Wait up to two seconds for the element to appear on the page.
 * Won't satisfy clients on really slow connections, but should
 * cover most without getting out of hand. If the browser does not
 * support sticky elements, additional offset will be ignored.
 *
 * @param {Function} queryForElem the query for the css element you want to scroll to
 * @param {Integer} additionalOffset usually the height of the crowbar header
 * @param {Integer} attempts the number of scroll attempts already made
 */
const makeScrollAttempt = (queryForElem, additionalOffset, attempts = 0) => {
  const elem = queryForElem()
  if (attempts >= 80) {
    return
  } else if (!!elem) {
    const position = supportsStickyPosition
      ? offsetTop(elem) + additionalOffset
      : offsetTop(elem)

    if (supportsNativeSmoothScroll) {
      window.scrollTo({ top: position, left: 0, behavior: 'smooth' })
    } else {
      alternateScrollTo(position, 1000)
    }
  } else {
    setTimeout(
      () => makeScrollAttempt(queryForElem, additionalOffset, attempts + 1),
      25
    )
  }
}

const offsetTop = element => {
  let top = 0
  while (element) {
    top += element.offsetTop
    element = element.offsetParent
  }
  return top
}
