const affiliateBrokerageInputKey = 'affiliate-brokerage-json'
const csrfInputField = 'csrf-token'
const currentVersionKey = 'current-version'
const isStagingKey = 'is-staging'
const isProductionKey = 'is-production'
const sessionInputKey = 'session-json'
const flashMessageKey = 'flash-message'
const segmentWriteKey = 'segment-write-key'
const userEmailKey = 'user-email'
const isTravelersRequestUrlKey = 'is-travelers-request-url'

const getInitialValue = () => {
  const affiliateBrokerageInput = document.getElementById(
    affiliateBrokerageInputKey
  )
  const sessionInput = document.getElementById(sessionInputKey)
  const csrfTokenInput = document.getElementById(csrfInputField)
  const currentVersionInput = document.getElementById(currentVersionKey)
  const isStagingInput = document.getElementById(isStagingKey)
  const isProductionInput = document.getElementById(isProductionKey)
  const flashMessageInput = document.getElementById(flashMessageKey)
  const segmentWriteKeyInput = document.getElementById(segmentWriteKey)
  const userEmailInput = document.getElementById(userEmailKey)
  const isTravelersRequestUrl = document.getElementById(
    isTravelersRequestUrlKey
  )

  const flashMessageValue = !!flashMessageInput ? flashMessageInput.value : ''
  const userEmailValue = !!userEmailInput ? userEmailInput.value : ''
  const affiliateBrokerage = !!affiliateBrokerageInput
    ? JSON.parse(affiliateBrokerageInput.value)
    : null
  const session = !!sessionInput ? JSON.parse(sessionInput.value).data : null
  const isTravelersRequestUrlValue = !!isTravelersRequestUrl
    ? isTravelersRequestUrl.value
    : 'false'

  if (!csrfTokenInput || !currentVersionInput || !isStagingInput) {
    return {}
  }

  return {
    initialData: session,
    affiliateBrokerage: affiliateBrokerage,
    browserVersion: detectBrowser(),
    csrfToken: csrfTokenInput.value,
    currentVersion: currentVersionInput.value,
    dateAtStartup: getFormattedDate(new Date()),
    isStaging: isStagingInput.value === 'true',
    isProduction: isProductionInput.value === 'true',
    flashMessage: flashMessageValue,
    segmentWriteKey: segmentWriteKeyInput.value,
    userEmail: userEmailValue,
    isTravelersRequestUrl: isTravelersRequestUrlValue === 'true',
  }
}

const getFormattedDate = date => {
  const year = date.getFullYear()

  let month = (1 + date.getMonth()).toString()
  month = month.length > 1 ? month : '0' + month

  let day = date.getDate().toString()
  day = day.length > 1 ? day : '0' + day

  return year + '-' + month + '-' + day
}

const detectBrowser = () => {
  if (
    !!window.chrome &&
    (!!window.chrome.webstore || !!window.chrome.runtime)
  ) {
    // Chrome 1 - 71
    return 'chrome'
  } else if (/*@cc_on!@*/ false || !!document.documentMode) {
    // Internet Explorer 6-11
    var userAgent = navigator.userAgent,
      tem,
      M =
        userAgent.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || []
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || []
      if (tem[1] == 11) {
        return 'ie11'
      } else {
        return 'lt-ie11'
      }
    } else {
      return 'lt-ie11'
    }
  } else if (typeof InstallTrigger !== 'undefined') {
    // Firefox 1.0+
    return 'firefox'
  } else if (
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === '[object SafariRemoteNotification]'
    })(
      !window['safari'] ||
        (typeof safari !== 'undefined' && safari.pushNotification)
    )
  ) {
    // Safari 3.0+
    return 'safari'
  } else if (
    !(/*@cc_on!@*/ (false || !!document.documentMode)) &&
    !!window.StyleMedia
  ) {
    // Edge 20+
    return 'edge'
  } else if (
    (!!window.opr && !!opr.addons) ||
    !!window.opera ||
    navigator.userAgent.indexOf(' OPR/') >= 0
  ) {
    // Opera 8.0+
    return 'opera'
  } else {
    return 'unknown'
  }
}

export { getInitialValue }
